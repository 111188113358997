import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Button, Row } from 'react-bootstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from '../components/footer'
import CommonFlex from './../components/commonFlex'
import Container from 'react-bootstrap/Container'
import { bdstatistics, apiURL, mqStatistics, GetQueryString } from '../components/census'
import StaticQueryCommon from './../components/articlesCommon'

import './../assets/css/aritcle.css'

export default () => {
  let [ active, setActive ] = React.useState(0)
  let [ hashActive, setHashActive ] = React.useState(null)

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
  }, [])

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      // 跳转到指定位置
      let product_media = document.getElementsByClassName('common-flex');
      if(hashActive) {
        for(let i=0; i<product_media.length;i++) {
          let name = product_media[i].getAttribute('name')
          if(name == hashActive) {
            window.scrollTo({
              top: product_media[i].offsetTop -120
            })
          }
        }
      }
    }
  }, [hashActive])

  const tag = GetQueryString('tag')

  return (
    <div className="aritcle-page">
      <div className="home-bg home-index" style={{ background: `url(${require("./../images/industry-banner.png")})`, backgroundSize: '100% 100%', height: '31.25rem' }}>
        <Layout>
          <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
          <div className="plat-jumbotron">
            <div className="plat-title news-title">News</div>
            {/* <div className="plat-desc" style={{  fontSize: '1.25rem', lineHeight: '1.75rem', color: '#fff' }}>使用物联网平台框架，客户快速搭建智慧城市、智慧油田等解决方案</div>   */}
          </div>  
        </Layout>
      </div>
      <Container> 
        <StaticQueryCommon type="industryDynamics" currentIndustry={tag} active={active} changeCommom={ (index, item) => { setActive(index); setHashActive(item) }}/>
      </Container>
    <Footer style={{ marginTop: '7.5rem' }}></Footer>
  </div>
  )
}